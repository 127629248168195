<template>
  <div
    v-loading="loading"
    class="audio-player flex flex-col"
  >
    <div
      v-if="!wavesurfer"
      class="audio-player__placeholder flex flex-center flex-col"
    >
      <img
        :src="require('@/assets/images/icons/upload-audio-asr.svg')"
        alt="audio upload icon"
      >
      <span class="audio-player__placeholder-label">{{ placeholder }}</span>
    </div>
    <div
      v-if="wavesurfer"
      class="audio-player__controls"
    >
      <div class="audio-player__controls-heading">
        <div class="audio-player__controls-heading__volume">
          <img
            class="icon"
            :src="require('@/assets/images/icons/volume.svg')"
            alt="volume"
          >
          <el-slider
            v-model="volume"
            class="slider"
            :show-tooltip="false"
          />
        </div>
        <button
          v-if="deletable"
          class="audio-player__controls__delete"
          @click="handleDelete"
        >
          <img
            :src="require('@/assets/images/icons/delete.svg')"
            alt="delete"
          >
        </button>
      </div>
      <button
        :disabled="disable"
        class="audio-player__controls__play"
        @click="handlePlayPause"
      >
        <img
          :src="actionIcon"
          class="audio-player__controls__play-icon"
          alt="play button"
        >
      </button>
    </div>

    <div
      class="waves"
      :class="{'waves--with-segments' : segmentsList.length}"
    >
      <div :id="containerId" />
      <div
        v-for="(segment, index) in segmentsList"
        :key="index"
        :style="[segment]"
        class="waves__segment"
      />
    </div>

    <span
      v-if="wavesurfer"
      class="playback-label"
    >{{ playbackLabel }}</span>
  </div>
</template>

<script>
import WaveSurfer from 'wavesurfer.js';
import { generateUUIDv4 } from '@/libs/hash';
import { floatSecondsToHHMMSS } from '@/libs/date';
import PlayDisabled from '@/assets/images/icons/play-disabled.svg';
import Play from '@/assets/images/icons/play.svg';
import Pause from '@/assets/images/icons/pause.svg';

const HUNDREDS_TO_ONES = 0.01;

export default {
  props: {
    disable: {
      type: Boolean,
      default: false,
    },
    deletable: {
      type: Boolean,
      default: true,
    },
    url: {
      type: String,
      default: '',
    },
    blob: {
      type: Blob,
      default: null,
    },
    placeholder: {
      type: String,
      default: '',
    },
    containerId: {
      type: String,
      default: () => `player-${generateUUIDv4()}`,
    },
    waveOptions: {
      type: Object,
      default: () => ({}),
    },
    segments: {
      type: Array,
      default: () => ([]),
    },
  },
  data: () => ({
    wavesurfer: null,
    volume: 100,
    currentTime: 0,
    playbackLabel: '00:00:00/00:00:00',
    loading: false,
  }),
  computed: {
    actionIcon() {
      if (this.disable) return PlayDisabled;

      if (!this.wavesurfer || !this.wavesurfer.isPlaying()) return Play;

      return Pause;
    },
    wavesurferOptions() {
      return {
        waveColor: '#D8DDED',
        progressColor: '#14D4B2',
        cursorWidth: 0,
        height: 156,
        responsive: true,
        ...this.waveOptions,
      };
    },
    segmentsList() {
      if (!this.wavesurfer) return [];

      const audioLength = this.wavesurfer.getDuration();
      return this.segments.map(({ start, end, color: backgroundColor }) => ({
        width: `${Math.round(((end - start) * 100) / audioLength)}%`,
        left: `${Math.round((start * 100) / audioLength)}%`,
        backgroundColor,
      }));
    },
  },
  watch: {
    blob(value) {
      if (!value) return this.handleDelete();

      return this.drawAudioPlayer();
    },
    async url(value) {
      if (this.blob) return null;

      if (!value) return this.handleDelete();

      return this.drawAudioPlayer();
    },
    volume(value) {
      if (!this.wavesurfer) return;

      const volume = value * HUNDREDS_TO_ONES;
      this.wavesurfer.setVolume(volume);
      this.$emit('volume-change', volume);
    },
  },
  beforeDestroy() {
    this.handleDelete({ emitDelete: false });
  },
  mounted() {
    if (!this.blob) return;

    this.drawAudioPlayer();
  },
  methods: {
    handleDelete({ emitDelete = true } = {}) {
      this.loading = true;
      if (this.wavesurfer) this.wavesurfer.destroy();

      this.wavesurfer = null;
      if (emitDelete) this.$emit('delete');
      this.loading = false;
    },
    handlePlayPause() {
      if (!this.wavesurfer) return;

      this.$emit('play-pause', this.wavesurfer.isPlaying());
      this.wavesurfer.playPause();
    },
    computeDuration(time, duration) {
      const currentTime = floatSecondsToHHMMSS(time);
      const currentDuration = floatSecondsToHHMMSS(duration);
      this.playbackLabel = `${currentTime}/${currentDuration}`;
    },
    drawAudioPlayer() {
      if (this.wavesurfer) this.wavesurfer.destroy();
      this.loading = true;
      const wavesurfer = WaveSurfer.create({
        container: `#${this.containerId}`,
        ...this.wavesurferOptions,
      });

      if (this.blob) wavesurfer.loadBlob(this.blob);
      else if (this.url) wavesurfer.load(this.url);

      wavesurfer.on('seek', () => this.$emit('seek', wavesurfer));
      wavesurfer.on('audioprocess', (time) => {
        const truncatedTime = Math.trunc(time);

        if (this.currentTime === truncatedTime) return;

        this.computeDuration(this.wavesurfer.getCurrentTime(), this.wavesurfer.getDuration());
        this.currentTime = truncatedTime;
      });
      wavesurfer.on('finish', () => {
        this.computeDuration(wavesurfer.getDuration(), wavesurfer.getDuration());
      });
      wavesurfer.on('ready', () => {
        this.loading = false;
        this.computeDuration(0, wavesurfer.getDuration());
        this.$emit('ready', wavesurfer);
      });
      this.wavesurfer = wavesurfer;
    },
  },
};
</script>

<style lang="scss" scoped>
.audio-player {
  position: relative;
  min-height: 1.8rem;
  border-radius: 4px;
  background: #F3F6FF;

  &__controls {
    z-index: 10;
    position: relative;
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &-heading {
      display: flex;
      justify-content: space-between;
      width: 100%;
      padding: 0.12rem;

      &__volume {
        display: flex;
        flex-direction: row;

        > .icon {
          width: 0.18rem;
        }

        > .slider {
          margin-left: 0.08rem;
        }
      }
    }

    &__play {
      border: none;
      background: none;
      margin-bottom: -0.24rem;

      &-icon {
        $icon-size: 0.54rem;
        width: $icon-size;
        height: $icon-size;
      }
    }

    &__delete {
      $icon-size: 0.36rem;
      width: $icon-size;
      height: $icon-size;
    }
  }

  &__placeholder {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    &-label {
      margin-top: 0.18rem;
      font-size: 0.14rem;
      color: $text-gray;
    }
  }

  .playback-label {
    margin: -0.16rem 0 0.32rem 0.32rem;
    font-size: 0.12rem;
    font-weight: 500;
    line-height: 0.14rem;
    color: $text-gray-dimmed;
  }

  .waves {
    position: relative;

    &--with-segments {
      margin-bottom: .2rem;
    }

    &__segment {
      position: absolute;
      top: 10%;
      height: 80%;
      border-radius: 5px;
      background-color: rgba($color: $background-green, $alpha: .3);
      pointer-events: none;
    }
  }
}
</style>
